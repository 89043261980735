@use "sass:math";


$font-family:"Montserrat-Regular",
"Libre Franklin",
"Helvetica Neue",
helvetica,
arial,
sans-serif;

$cm:#333;
$blue:#4104DD;

$m-space:4%;
$m_base_width:750;

.visible{
    visibility: hidden;
    opacity:0;
    transition:.7s;

    &.on{
        visibility: visible;
        opacity:1;
    }
}

.text-shadow {
    color: rgba(255, 255, 255, .8);
    text-shadow: 0 0 5px #431EA7;
}

.CanelaDeck-Medium {
    font-family: "CanelaDeck-Medium";
}

.CanelaDeck-Bold {
    font-family: "CanelaDeck-Bold";
}

.scrollbar {

    /*定义滚动条高宽及背景
    高宽分别对应横竖滚动条的尺寸*/
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #F5F5F5;
    }

    /*定义滚动条轨道
    内阴影+圆角*/
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        background-color: #F5F5F5;
    }

    /*定义滑块
    内阴影+圆角*/
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #555;
    }
}



$pc_base_width:1920;
$header-height:40px;

@function px2rem($px) {
    @return math.div($px*100, $pc_base_width)*1rem;
}

@function div($a, $b) {
    @return math.div($a, $b);
}

// @function fs($px) {
//     @return $px*10/$mobileWidth*1rem;
// }
@font-face {
	font-family: 'CanelaDeck-Regular';
	src:url(front/assets/fonts/Canela-Regular.woff) format('woff');
}

@font-face {
	font-family: 'CanelaDeck-Medium';
	src:url(front/assets/fonts/Canela-Medium.woff) format('woff');
}

@font-face {
	font-family: 'Canela-Bold';
	src:url(front/assets/fonts/Canela-Bold.woff) format('woff');
}

.l{float:left;}.r{float:right;}.cl{clear:both;}
.n{font-weight:normal;font-style:normal;}.b{font-weight:bold;}.i{font-style:italic;}
.tl{text-align:left;}.tc{text-align:center;}.tr{text-align:right;}.tj{text-align:justify;}
.ti2{text-indent:2em;}
.c0{color:#000;}.c1{color:#333;}.c2{color:#666;}.c3{color:#999;}.cb{color:#2a95f8;}.cg{color:#8B8B8B;}.cm{color:$cm;}.co{color:#fe9001;}.cc{color:#CCC;}.cr{color:red;}.cw{color:#FFF;}.chm{color:#1F2021;}/* rgb(31,32,33) */
/* font size */
.fs{font-size:10vw;}  /* 文字按比例缩放 */
.f3s{font-size:.714em;}
.f2s{font-size:.785em;}
.f1s{font-size:.85em;}
.fm{font-size:1em;}
.f1x{font-size:1.14em;}
.f2x{font-size:1.28em;}

.vt{vertical-align:top;}.vm{vertical-align:middle;}.vb{vertical-align:bottom;}
.m0{margin:0;}.ml1{margin-left:1em;}.mr1{margin-right:1em;}.mt1{margin-top:1em;}
.p0{padding:0;}.pl1{padding-left:1em;}.pr1{padding-right:1em;}.pt1{padding-top:1em;}.pb1{padding-bottom:1em;}
.pr{position:relative;}.pa{position:absolute;}.ps{position:static;}.pf{position:fixed;}
.df{display:flex;}.dn{display:none;}.db{display:block;}.dib{display:inline-block;}.di{display:inline;}
.f1{flex:1}
.aic{align-items: center;}
.csb{align-items: center;justify-content: space-between;}
.oh{overflow:hidden;}.os{overflow:scroll;}
.vh{visibility:hidden;}.vv{visibility:visible;}
.cf:after{display:block;content:""; height:0;clear:both;overflow:hidden;visibility:hidden;}
.e{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;min-width: 0;}
.nowrap{white-space: nowrap;}
.m{background:none no-repeat 50% 50%;background-size:cover;}
.wpr{margin:0 5%;}.wpr2{margin:5%;}
.con{padding:0 5%;}.con2{padding:5%;}
.w100{width:100%;}
.clamp{line-height:1.2em;max-height:2.4em;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;overflow: hidden;}
.lb{padding-bottom:5em;}
.article>image{display:block;width:100%;max-width:100%;}
.p{margin:1em 0;min-height:1em;}
.pre{white-space: pre-wrap;}
.nowrap{white-space: nowrap;}
.hand{cursor: pointer;}
.loading,
.empty-loading:empty{background:url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHN0eWxlPmNpcmNsZXtzdHJva2UtZGFzaG9mZnNldDoxNjA7LXdlYmtpdC1hbmltYXRpb246YSAxLjZzIGluZmluaXRlO2FuaW1hdGlvbjphIDEuNnMgaW5maW5pdGU7fUAtd2Via2l0LWtleWZyYW1lcyBhezEwMCV7c3Ryb2tlLWRhc2hvZmZzZXQ6LTE2MDt9fUBrZXlmcmFtZXMgYXsxMDAle3N0cm9rZS1kYXNob2Zmc2V0Oi0xNjA7fX08L3N0eWxlPgo8Y2lyY2xlIGN4PSIzMC4zMTAyNSIgY3k9IjI5LjgzMjkzIiByPSIyMi45MTE2OSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjREREIiBzdHJva2Utd2lkdGg9IjYiIHN0cm9rZS1kYXNoYXJyYXk9IjE2MCwxNjAiIC8+Cjwvc3ZnPg==) no-repeat 50% 50%;background-size:3em;}
.empty-loading{
   height:100vh;
}
.page-lazy-loading{
	height:100vh;
}

html{font-size:19.2px;font-size:1vw;}
body{width:100%;min-width:360px;font: normal 14px/1.5 "CanelaDeck-Regular",$font-family;color:#333;background:#FFF;}
html,body{
	@extend .scrollbar;
}
main{display:block;}
ul{list-style-type: none;}
body,ul,dl,dt,dd{
  margin:0;
  padding:0;
}

.editor-video{
	width:100%;
	
}

@media (max-width:980px) {
	html{font-size:19.5px;font-size:2.6vw;}
}